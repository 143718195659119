import { ReactComponent as CalendarIcon } from './icons/calendar.svg';
import { ReactComponent as DismissIcon } from './icons/dismiss.svg';
import cx from 'classnames';
import { Calendar } from './Calendar';
import { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { IconButton } from 'components/IconButton';
import { Button } from 'components/Button';
import classes from './date.module.scss';
import { TimePicker } from './TimePicker';
import { ValidationMessage } from 'components/Form/ValidationMessage';
import { Portal } from 'components/Select';
import { Trans, useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import {
  getFormattedNow,
  stringToDate,
  toDisplayDate,
  toIsoDate,
  parseDate,
  dateToOutputString,
  exprUSADate,
  exprUSAOutput,
} from 'lib/adapter';
import { DATE_PLACEHOLDER, DATETIME_PLACEHOLDER } from 'lib/const';
import { devLog } from 'lib/helpers';
import { addMinutes } from 'date-fns';

const minutesIncrement = 15;

export type TDateTimePickerProps = {
  value?: any;
  placeholder?: string;
  hasError?: boolean;
  onChange: (value?: string) => void;
  showTime?: boolean;
  canSelectPastDate?: boolean;
  canSelectFutureDate?: boolean;
};

const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
const timeMask = [/\d/, /\d/, ':', /\d/, /\d/, ' ', /[AaPp]/, /[Mm]/];

const stringToDateOrUndefined = (value: any, showTime = false) => {
  if (!value) return;
  try {
    return stringToDate(value, showTime);
  } catch (e) {
    devLog(e);
  }
};

const roundDate = (date = new Date()) => {
  date.setSeconds(0);
  if (date.getMinutes() % minutesIncrement === 0) return date;
  return addMinutes(date, minutesIncrement - (date.getMinutes() % minutesIncrement));
};

export const DateTimePicker = ({
  showTime = false,
  value,
  onChange,
  hasError,
  canSelectPastDate = true,
  canSelectFutureDate = true,
}: TDateTimePickerProps) => {
  const { t } = useTranslation();

  const expr = useMemo(() => (showTime ? exprUSAOutput : exprUSADate), [showTime]);

  const currentValue = useMemo(() => {
    if (showTime)
      return dateToOutputString(roundDate(parseDate(expr.test(value) ? value : getFormattedNow(), showTime)));
    return value && expr.test(value) ? toDisplayDate(value, showTime) : getFormattedNow();
  }, [expr, showTime, value]);

  const [date, time] = useMemo(() => [currentValue.substr(0, 10), currentValue.substr(11)], [currentValue]);

  const [now, minPossibleDate, maxPossibleDate] = useMemo(
    () => [new Date(), new Date(1, 1, 1), new Date(9999, 1, 1)],
    []
  );

  const minDateTime = useMemo(
    () => (canSelectPastDate ? minPossibleDate : now),
    [canSelectPastDate, minPossibleDate, now]
  );

  const maxDateTime = useMemo(
    () => (canSelectFutureDate ? maxPossibleDate : now),
    [canSelectFutureDate, maxPossibleDate, now]
  );

  const [isOpened, setOpened] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(() => stringToDateOrUndefined(value, showTime));
  const [listStyles, setListStyles] = useState({});

  const wrapperRef = useRef<HTMLDivElement>(null);
  const wrapperPortalRef = useRef<HTMLDivElement>(null);
  const wrapperTimePickerListRef = useRef<HTMLDivElement>(null);

  const checkListPosition = useCallback(() => {
    if (wrapperRef.current) {
      const element = document.scrollingElement || document.documentElement;
      const { width: docWidth, height: docHeight } = element.getBoundingClientRect();
      const {
        left: nodeLeft,
        top: nodeTop,
        bottom: nodeBottom,
        right: nodeRight,
      } = wrapperRef.current.getBoundingClientRect();
      const contentHeight = showTime ? 365 : 264;
      const contentWidth = 220;

      const displayOnTop = docHeight / 2 < nodeTop;
      const toLeft = nodeLeft + contentWidth < docWidth;

      setListStyles({
        ...(toLeft ? { left: nodeLeft } : { right: docWidth - nodeRight + contentWidth }),
        ...(displayOnTop ? { bottom: docHeight - nodeTop + contentHeight } : { top: nodeBottom }),
        maxHeight: contentHeight,
      });
    }
  }, [showTime]);

  const toggleDropdown = useCallback(() => {
    checkListPosition();
    setOpened(!isOpened);
  }, [checkListPosition, isOpened]);

  const errors = useMemo(() => {
    if (!selectedDate) return '';

    const now = new Date();
    const date = selectedDate as Date;

    return date < (canSelectPastDate ? minPossibleDate : now)
      ? t('Time can’t be in the past')
      : date > (canSelectFutureDate ? maxPossibleDate : now)
        ? t('Time can’t be in the future')
        : '';
  }, [canSelectFutureDate, canSelectPastDate, maxPossibleDate, minPossibleDate, selectedDate, t]);

  const onClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        wrapperPortalRef &&
        wrapperPortalRef.current &&
        !wrapperPortalRef.current.contains(e.target as Node) &&
        !wrapperTimePickerListRef?.current?.contains(e.target as Node)
      ) {
        e.preventDefault();
        e.stopPropagation();
        setSelectedDate(stringToDateOrUndefined(value));
        setOpened(false);
      }
    },
    [value]
  );

  const { current: observer } = useRef(
    new IntersectionObserver(([{ intersectionRatio }]) => {
      if (intersectionRatio < 1) setOpened(false);
    })
  );

  useEffect(() => {
    if (isOpened) {
      window.addEventListener('mousedown', onClickOutside, true);
      observer.observe(wrapperRef.current as Element);
    }
    return () => {
      observer.disconnect();
      window.removeEventListener('mousedown', onClickOutside, true);
    };
  }, [isOpened, observer, onClickOutside]);

  const onTimeChange = useCallback(
    (time: string) => {
      onChange(`${date} ${time}`);

      const newDate = stringToDateOrUndefined(`${date} ${time}`, showTime);
      setSelectedDate(newDate);
    },
    [date, onChange, showTime]
  );

  const onDateChange = useCallback(
    (date: string) => {
      const displayDate = toDisplayDate(date, false);
      onChange(showTime ? `${displayDate} ${time}` : displayDate);

      const newDate = stringToDateOrUndefined(showTime ? `${displayDate} ${time}` : displayDate, showTime);
      setSelectedDate(newDate);

      if (!showTime) setOpened(false);
    },
    [showTime, onChange, time]
  );

  const save = useCallback(() => {
    onChange(`${date} ${time}`);
    setOpened(false);
  }, [date, onChange, time]);

  const clearDate = useCallback(() => {
    onChange('');
    setSelectedDate(undefined);
  }, [onChange, setSelectedDate]);

  return (
    <div ref={wrapperRef} className={cx(classes.wrapper, { [classes.focus]: isOpened })} tabIndex={0}>
      <div className={cx(classes.controlBox, classes?.inputWrapper, { [classes.hasError]: hasError })}>
        <MaskedInput
          key={value}
          mask={[...dateMask, ...(showTime ? [' ', ...timeMask] : [])]}
          className={classes.input}
          type="text"
          defaultValue={value}
          onBlur={(e) => onChange(e.target.value.toUpperCase())}
          placeholder={showTime ? DATETIME_PLACEHOLDER : DATE_PLACEHOLDER}
        />
        {!!value && (
          <IconButton className={cx(classes.button, classes?.clear)} Icon={DismissIcon} onClick={clearDate} />
        )}
        <IconButton className={classes.button} Icon={CalendarIcon} onClick={toggleDropdown} />
      </div>
      {isOpened && (
        <Portal redraw={checkListPosition}>
          <div ref={wrapperPortalRef} className={classes.listWrapper} style={listStyles}>
            <div className={cx(classes.root, { [classes.showTime]: showTime })}>
              <Calendar onChange={onDateChange} value={toIsoDate(date)} minDate={minDateTime} maxDate={maxDateTime} />
              {showTime && (
                <>
                  <div className={classes.time}>
                    <TimePicker
                      listRef={wrapperTimePickerListRef}
                      onChange={onTimeChange}
                      value={time}
                      minutesIncrement={minutesIncrement}
                    />
                    <ValidationMessage error={errors} absolute={true} />
                  </div>
                  <div className={classes.footerButtons}>
                    <Button className={classes.button} role="primary" onClick={save} disabled={!!errors}>
                      <Trans>Save</Trans>
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};
